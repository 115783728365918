(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("sbJsExtends"), require("sbLocalizationLib"), require("sbDataLayer"), require("sbRespBlockLib"), require("sbMsgBusModule"), require("sbInternalMsgBus"), require("gsap"));
	else if(typeof define === 'function' && define.amd)
		define("sb-open-bets", ["React", "mobx", "mobxReact", "sbJsExtends", "sbLocalizationLib", "sbDataLayer", "sbRespBlockLib", "sbMsgBusModule", "sbInternalMsgBus", "gsap"], factory);
	else if(typeof exports === 'object')
		exports["sb-open-bets"] = factory(require("React"), require("mobx"), require("mobxReact"), require("sbJsExtends"), require("sbLocalizationLib"), require("sbDataLayer"), require("sbRespBlockLib"), require("sbMsgBusModule"), require("sbInternalMsgBus"), require("gsap"));
	else
		root["sb-open-bets"] = factory(root["React"], root["mobx"], root["mobxReact"], root["sbJsExtends"], root["sbLocalizationLib"], root["sbDataLayer"], root["sbRespBlockLib"], root["sbMsgBusModule"], root["sbInternalMsgBus"], root["gsap"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__23__) {
return 